export interface ModelPayload {
  modelName?:string,
  modelUid?: string,
  data?: any,
  query?: string,
  params?:any
}

export interface ParamsPayload {
  q?: string,
  page?: number,
  perPage?: number,
  dateKeyword?: string,
  sort?:string,
  order?:string,
  status?:string
}

export function defaultParamsPayload (): ParamsPayload {
  return {
    page: 1,
    perPage: 10
  }
}
