import { render, staticRenderFns } from "./AdminUpdateUserSidebar.vue?vue&type=template&id=4acb83ec&"
import script from "./AdminUpdateUserSidebar.vue?vue&type=script&lang=ts&"
export * from "./AdminUpdateUserSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./AdminUpdateUserSidebar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports