import { Component, Vue } from 'vue-property-decorator'
import { defaultParamsPayload, ParamsPayload } from '@/models/Payloads'
import { Getter } from '@/store/decorators'
import AuthModule from '@/store/modules/authModule'
import { User } from '@/models/User'

@Component({
  name: 'MixinFunctions'
})
export default class MixinFunctions extends Vue {
  @Getter(AuthModule, 'activeUser') public activeUser!: User

  async wrapAsync (promise: Promise<any>, successMessage: string) {
    try {
      const response = await promise
      if (successMessage && successMessage !== '') {
        // this.$vs.notify({
        //   title: 'Success',
        //   text: successMessage,
        //   color: 'success',
        //   position: 'top-right'
        // })
      }
      return response
    } catch (errorData) {
      if (errorData.errors) {
        for (let [key, value] of Object.entries(errorData.errors)) {
          // console.log(key, value)
          // this.$vs.notify({
          //   title: ' Error: ',
          //   text: errorData.code === 412 ? value : value[0],
          //   iconPack: 'feather',
          //   icon: 'icon-alert-circle',
          //   color: 'danger'
          // })
        }
      }
      if (errorData.error) {
        // this.$vs.notify({
        //   title: ' Error: ',
        //   text: errorData.error.message,
        //   iconPack: 'feather',
        //   icon: 'icon-alert-circle',
        //   color: 'danger'
        // })
      }
      return null
    }
  }

  cleanCopy (obj: any) {
    return JSON.parse(JSON.stringify(obj))
  }

  loadQueryString (paramsPayload: ParamsPayload) {
    paramsPayload.page = 1
    if (JSON.stringify(this.$route.query) !== '{}') {
      for (let queryKey in this.$route.query) {
        if (queryKey === 'page') {
          paramsPayload.page = parseInt(String(this.$route.query[queryKey]))
        } else if (queryKey === 'per_page') {
          paramsPayload.perPage = parseInt(String(this.$route.query[queryKey]))
        } else if (queryKey === 'date_keyword') {
          paramsPayload.dateKeyword = String(this.$route.query[queryKey])
        } else {
          paramsPayload[queryKey] = String(this.$route.query[queryKey])
        }
      }
      paramsPayload.page = parseInt(String(this.$route.query.page))
    }
    return paramsPayload
  }
}
