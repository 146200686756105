export interface Pagination {
  currentPage: number,
  perPage: number,
  total: number,
  from: number,
  to: number,
  data: any[]
}

export function defaultPagination (): Pagination {
  return {
    currentPage: 1,
    perPage: 10,
    total: 0,
    from: 1,
    to: 1,
    data: []
  }
}
