// export interface UserAccountMenuTabs {
//   headerLabel: string
//   tabLabel: string
//   iconClass: string
//   componentName: string
// }

export interface ShortUser {
  uid: string
  firstName: string
  lastName: string
}

export interface User {
  uid?: string
  firstName: string
  lastName: string
  email: string
  authToken: string
  name: string
  status: string
  emailVerified: boolean
  userRole: string
  avatar: string,
  roles: string[]
}

export interface UserProviderData {
  providerUserId: string
  email: string
  token: string
  refreshToken: string
  name: string
  firstName?: string
  lastName?: string
  avatar?: string
  emailVerified: boolean
  provider: string
}

export interface Owner extends ShortUser{

}

export interface UserItem extends User{

}

export function userRoleOptions (): string[] {
  return [
    'Admin',
    'Customer',
    'Provider'
  ]
}

export function userStatusOptions (): string[] {
  return [
    'Active',
    'Not Active',
    'Deleted'
  ]
}
