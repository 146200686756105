

















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BBadge,
  BLink,
  BFormInput,
  BPagination,
  BTable,
  BCard,
  BSpinner,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BRow,
  BCol,
  BButton,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { defaultPagination, Pagination } from '@/models/General'
import { Action } from '@/store/decorators'
import { CustomerModule } from '@/store/modules/customerModule'
import { ProviderModule } from '@/store/modules/providerModule'
import { UserModule } from '@/store/modules/userModule'
import { avatarText } from '@core/utils/filter'
import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'
import AdminAddUserSidebar from '@/views/admin/components/AdminAddUserSidebar.vue'
import AdminUpdateUserSidebar from '@/views/admin/components/AdminUpdateUserSidebar.vue'
import { UserRoleEnums } from '@/enums/User'
import { userRoleOptions } from '@/models/User'

@Component({
  name: 'AdminUsersList',
  components: {
    BBadge,
    BLink,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BRow,
    BCol,
    BButton,
    BSidebar,
    VSelect,
    AdminAddUserSidebar,
    AdminUpdateUserSidebar
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminUsersList extends mixins(Vue, MixinFunctions) {
  @Action(CustomerModule, 'fetchAdminCustomerUsers') private fetchAdminCustomerUsers!: (payload: ModelPayload) => Promise<Pagination>
  @Action(ProviderModule, 'fetchAdminProviderUsers') private fetchAdminProviderUsers!: (payload: ModelPayload) => Promise<Pagination>
  @Action(UserModule, 'fetchAdminUsers') private fetchAdminUsers!: (payload: ModelPayload) => Promise<Pagination>

  $refs!: { refUserTable }

  private fields: object = [
    { key: 'user' },
    { key: 'email' },
    { key: 'roles' },
    { key: 'account_status', label: 'Status' },
    { key: 'actions', thStyle: { width: '1%' } }
  ]

  private UserRoleList: string[] = userRoleOptions()
  private userRole: string = UserRoleEnums.Customer
  private userPagination: Pagination = defaultPagination()
  private avatarText: object = avatarText
  private paramsPayload: ParamsPayload = defaultParamsPayload()
  private currentPage: number = 1
  private perPage: number = 10
  private perPageOptions: number[] = [10, 25, 50, 100]
  private totalData: number = 0
  private searchQuery: string = ''
  private searchQueryTimeout: any = null

  private created () {
    // TODO need to load the query string at the first load
    this.paramsPayload = this.loadQueryString(this.paramsPayload)
  }

  private selectedRole : string = 'Customer'
  @Watch('selectedRole', { deep: true })
  private onSelectedRole () {
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refUserTable.refresh()
  }

  private userStatus: object = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ]

  private selectedUserStatus: any = { label: 'All', value: '' }
  @Watch('selectedUserStatus', { deep: true })
  private onSelectedUserStatus () {
    this.paramsPayload.status = this.selectedUserStatus.value
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }

  private userData: object = {};
  private passCustomerData (userData) {
    this.userData = userData
  }

  private async loadUsers () {
    try {
      if (this.selectedRole === 'Admin') {
        this.userPagination = await this.fetchAdminUsers({
          params: this.paramsPayload
        })
      } else if (this.selectedRole === 'Customer') {
        this.userPagination = await this.fetchAdminCustomerUsers({
          params: this.paramsPayload
        })
      } else if (this.selectedRole === 'Provider') {
        this.userPagination = await this.fetchAdminProviderUsers({
          params: this.paramsPayload
        })
      }
      this.userRole = this.selectedRole
      this.totalData = this.userPagination.total
      return this.userPagination.data
    } catch (error) {
      // console.error(error)
    }
  }

  get dataMeta () {
    const localItemsCount = this.$refs.refUserTable ? this.$refs.refUserTable.localItems.length : 0

    return {
      from: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + (localItemsCount ? 1 : 0),
      to: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + localItemsCount,
      of: this.totalData
    }
  }
  @Watch('paramsPayload', { deep: true, immediate: false })
  private refreshTable () {
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refUserTable.refresh()
  }

  @Watch('searchQuery')
  private onSearchQuery () {
    this.paramsPayload.q = this.searchQuery
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }
}
