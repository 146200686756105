





























































import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  BContainer,
  BCardBody,
  BCardTitle,
  BCardText,
  BCard,
  BCol,
  BRow,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { userRoleOptions } from '@/models/User'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'
import { UserRoleEnums } from '@/enums/User'

@Component({
  name: 'AdminAddUserSidebar',
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BContainer,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,

    VSelect,

    ValidationProvider,
    ValidationObserver
  }

})
export default class AdminAddUserSidebar extends mixins(Vue, MixinFunctions) {
  @Prop({ default: 'Customer' }) userRole!: string
  $refs!: { addUserRules }
  private userEmail:string=''
  private userRoleLocal:string = UserRoleEnums.Customer
  private userRoleOptions = userRoleOptions()
  private mounted () {
    const rules = [email, required]
  }

  private created () {
    this.userRoleLocal = this.cleanCopy(this.userRole)
  }

  private validationForm () {
    this.$refs.addUserRules.validate().then(success => {
      // TODO call invite user to a role

      if (success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This is for UI purpose only.',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
      }
    })
  }
}
